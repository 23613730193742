// Sidebar.js
import React from 'react';
import NavItem from './NavItem';
import './Sidebar.css';

const Sidebar = ({ buttons }) => {
    return (
        <div className="sidebar">
            <div className="nav-container">
                {buttons.map((button, index) => (
                    <NavItem key={index} icon={button.icon} text={button.text} to={button.link}/>
                ))}
                </div>
            </div>
            );
            };

            export default Sidebar;
