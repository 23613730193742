export const CompanyFormConfig = [
    {
        fields: [
            {
                name: "industry_focus",
                subtitle: "Which Industry",
                label: "Industries",
                type: "multi-select",
                fetchOptions :true,
            },
            {
                name: "stages",
                subtitle: "Investment Stage",
                label: "Choose the you are looking for",
                type: "single-select",
                fetchOptions :true,

            },
            {
                name: "name",
                subtitle: "Your Company's name",
                type: "text",
                placeholder: "Company name",
                fetchOptions :false,
            },
            {
                name: "website",
                subtitle: "Company's website",
                type: "text",
                placeholder: "Paste URL Here",
                fetchOptions :false,
            },
        ],
    },
    {
        fields: [
            {
                name: "amount",
                type: "text",
                subtitle: "How much are you intending to raise? (in $)",
                placeholder: "Number followed by K, M, or B (e.g., 500K, 1M, 2B)",
                fetchOptions :false,

            },
            {
                name: "raisedMoney",
                label: "Have you raised money before?",
                type: "single-select",
                fetchOptions :false,
                options: [
                    "Yes",
                    "No",
                ]
            },
            {
                name: "description",
                type: "text",
                subtitle: "Tell us about your company and what you're looking for",
                placeholder: "Enter a prompt E.g Follow conversations about the client ACME company,  any chat about new clients and client churn only",
                multiline: true,
                fetchOptions :false,
            },
        ],
    },
];
