// src/components/Navbar.js
import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Button from "../common/Button";
import logo from '../../assets/images/LeanLogoDark.png';
import './Navbar.css';
import emptyUser from '../../assets/images/empty-user.png';
import {NavLink, useLocation} from "react-router-dom";
import useTrackEvent from '../../hooks/useTrackEvent';

const Navbar = () => {
    const { user } = useContext(AuthContext);
    const location = useLocation();
    const isAppActive = location.pathname.startsWith("/");
    const { trackAction } = useTrackEvent({})
    const handleGuidesClick = () => {
        trackAction('page_view', {
            'page': 'Guides'
        });
    };

    return (
        <header className="header">
            <div className="container">
                <div className="logo">
                    <img src={logo} alt="VentureLink Logo" style={{height :'70px'}}/>
                </div>
                <nav className="navigation">
                    <NavLink
                        to="/"
                        className={({isActive}) =>
                            `nav-item1 ${isAppActive ? "active" : ""}`
                        }
                    >
                        App
                    </NavLink>
                    <NavLink
                        to="https://www.leanventurehub.com/guides"
                        className={({isActive}) =>
                            `nav-item1 ${isActive ? "active" : ""}`
                        }
                        onClick={handleGuidesClick} // Track click event
                    >
                        Guides
                    </NavLink>
                </nav>
                <div className="nav-actions">
                    {user ? (
                        <>
                            <div className="user-avatar">
                                <a href={'/profile'}>
                                {user.logo ? (
                                    <img src={user.logo} alt="User Avatar" className="user-avatar"/>
                                ) : (
                                    <img src={emptyUser} alt="User Avatar" className="user-avatar"/>
                                )}
                                </a>
                            </div>
                            <span className="username">{user.name}</span>
                        </>
                    ) : (
                        <>
                            <Button link="/login" text="login"/>
                            <Button link="/signup" text="signup"
                                    style={{
                                        background: '#7F56D9',
                                        border: '1px solid #7F56D9',
                                        boxshadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                        borderradius: '8px'
                                    }}/>
                        </>
                    )}

                </div>
            </div>
        </header>
    );
};

export default Navbar;
