import React, {useContext, useState} from "react";
import DynamicFormPage from "../../components/Form/DynamicFormPage";
import {Box, Button, CircularProgress, Typography} from "@mui/material";
import {AuthContext} from "../../context/AuthContext";
import BG from '../../assets/images/BG.png';
import logo from '../../assets/images/LeanLogoDark.png';
import {CompanyFormConfig} from "../../config/CompanyFormConfig";
import api from "../../services/api";
import {useLocation, useNavigate} from "react-router-dom";
import useLoading from "../../hooks/useLoading";
import CloseIcon from "@mui/icons-material/Close"; // MUI close icon


const FormFlow = () => {
    const location = useLocation();
    const isEdit = location.state?.edit || false;
    const initialFormData = location.state?.companyInfo || {};
    const [step, setStep] = useState(0);
    const { user} = useContext(AuthContext);
    const name = user.name.split(' ')[0];
    const [formData, setFormData] = useState(initialFormData);
    const navigate = useNavigate();
    const {showSpinner, startLoading, stopLoading} = useLoading(false,0);
    const [errors, setErrors] = useState({});

    const updateCompany = async () => {
        startLoading();
        try {
             await api.post("/company/update", formData, {
                headers: {"Content-Type": "multipart/form-data"},
            });
            console.log("Company updated successfully!");
            navigate("/");
        } catch (error) {
            console.error("Error updating user Company:", error);
            alert("Failed to update Company.");
        }finally {
            stopLoading()
        }

    };
    const handleValidation = () => {
        const newErrors = {};

        // Validate amount field
        const amountRegex = /^[0-9]+(K|M|B)$/; // Match numbers with K, M, or B
        if (formData.amount && !amountRegex.test(formData.amount)) {
            newErrors.amount = "Amount must be a number followed by K, M, or B (e.g., 500K, 1M, 2B).";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0; // Return true if no errors
    };

    const handleNext = async () => {
        if (handleValidation()) {
            if (step < CompanyFormConfig.length - 1) {
                setStep(step + 1);
            } else {
                await updateCompany(); // Proceed to the next step if validation passes
            }
        }
    };

    const handleBack = () => {
        if (step > 0) {
            setStep(step - 1);
        }
    };

    const handleClose = () => {
        navigate("/profile");
    }


    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: "url('blur_BG.png')",
                backgroundColor: "black",
            }}
        >
            {showSpinner ? (
                <div style={{display: 'flex', justifyContent: 'center', padding: '16px', alignItems:"center"}}>
                    <CircularProgress size={48} sx={{color:"#1683D5"}}/>
                    <Typography variant="T1" sx={{color:"white", ml :4}}>We are looking for the best Recommendations For you!</Typography>
                </div>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        zIndex: 2,
                        backgroundColor: "#191924",
                        color: "#fff",
                        borderRadius: "16px",
                        width: "80%",
                        overflow: "hidden",
                        height: "815px",
                    }}
                >
                    <img src={BG} alt="BG" style={{position: "absolute", zIndex: -1}}/>
                    <img src={logo} alt="logo" style={{position: "absolute" ,width :"147px", marginTop:"37px", marginLeft :"57px"}}/>


                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            padding: "2rem",
                        }}
                    >
                        <Box
                            sx={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "start",
                                textAlign: "start",
                                ml: 15,
                                mr: 6
                            }}
                        >
                            <Typography variant="T5" sx={{mb: 2, textAlign: "start"}}>
                                {!isEdit ? `Welcome ${name}!` : `Hi ${name}`}
                            </Typography>
                            <Typography variant="T6">
                                {!isEdit ? `Let’s find the best VC fit for your needs` :`Let’s edit your info.`}
                            </Typography>
                        </Box>
                    </Box>
                    <Box sx={{width :"50%", position : "relative"}}>
                        {isEdit && (
                            <Button
                                onClick={handleClose} // Handle button click event
                                sx={{
                                    position: "absolute",
                                    top: "14px",
                                    right: "24px",
                                    minWidth: "32px",
                                    height: "32px",
                                    borderRadius: "50%",
                                    padding: "0",
                                    color: "white",
                                    background: "#323248",
                                    border :"none",
                                    "&:hover": { background: "#323248", color: "white", },
                                }}
                            >
                                <CloseIcon fontSize="small" />
                            </Button>
                        )}
                        <DynamicFormPage
                            config={CompanyFormConfig[step]}
                            step={step + 1}
                            formData={formData}
                            setFormData={setFormData}
                            onNext={handleNext}
                            onBack={handleBack}
                            isLastStep={step === CompanyFormConfig.length - 1}
                            errors={errors}
                            setErrors={setErrors}
                        />
                    </Box>
                </Box>
        )}
        </Box>
    );
};

export default FormFlow;
