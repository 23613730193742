const companyFieldConfig = [
    { key: "industries", title: "Business Industry", isList:true },
    { key: "raised_money", title: "Have you raised funds before" },
    { key: "stage", title: "Investment Stage" },
    { key: "check_size", title: "Funding Needs" },
    { key: "website", title: "Company Website", isLink: true },
    { key: "description", title: "A little bit about your business" },

];

export default companyFieldConfig;
