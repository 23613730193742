import React, { useEffect, useRef, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    CircularProgress, Typography,
} from "@mui/material";
import { COLUMN_WIDTHS } from "../../../pages/VC/config";

const DataTable = ({
                       columns,
                       data,
                       visibleColumns,
                       sortOrder,
                       onSortChange,
                       renderCellContent,
                       isLoading,
                   }) => {
    const tableContainerRef = useRef(null);
    const [columnWidths, setColumnWidths] = useState({});

    useEffect(() => {
        const calculateColumnWidths = () => {
            if (tableContainerRef.current) {
                const containerWidth = tableContainerRef.current.clientWidth;

                // Calculate total default widths of visible columns
                const visibleColumnsConfig = columns.filter((column) =>
                    visibleColumns[column.id]
                );
                const totalDefaultWidth = visibleColumnsConfig.reduce(
                    (sum, column) => sum + (COLUMN_WIDTHS[column.id]?.width || 100),
                    0
                );

                const isOverflowing = containerWidth < totalDefaultWidth;

                // Calculate proportional widths
                const calculatedWidths = {};
                visibleColumnsConfig.forEach((column) => {
                    const colConfig = COLUMN_WIDTHS[column.id];
                    const defaultWidth = colConfig?.width || 100;

                    const proportionalWidth = isOverflowing
                        ? (defaultWidth / totalDefaultWidth) * containerWidth
                        : defaultWidth;

                    calculatedWidths[column.id] = {
                        minWidth: colConfig?.minWidth || 100,
                        maxWidth: colConfig?.maxWidth || 300,
                        width: Math.max(
                            colConfig?.minWidth || 100,
                            Math.min(proportionalWidth, colConfig?.maxWidth || 300)
                        ),
                    };
                });

                setColumnWidths(calculatedWidths);
            }
        };

        calculateColumnWidths();

        // Recalculate on window resize
        window.addEventListener("resize", calculateColumnWidths);
        return () => window.removeEventListener("resize", calculateColumnWidths);
    }, [columns, visibleColumns]);

    return (
        <TableContainer ref={tableContainerRef} style={{ overflowX: "auto",
            width: "100%",
            borderRadius: "12px", // Round corners
        }}>
            {isLoading ? (
                <div style={{ display: "flex", justifyContent: "center", padding: "16px" }}>
                    <CircularProgress size={48} />
                </div>
            ) : (
                <Table style={{ tableLayout: "fixed", width: "100%"  }}>
                    <TableHead>
                        <TableRow>
                            {columns
                                .filter((column) => visibleColumns[column.id])
                                .map((column) => (
                                    <TableCell
                                        key={column.id}
                                        style={{
                                            minWidth: columnWidths[column.id]?.minWidth,
                                            maxWidth: columnWidths[column.id]?.maxWidth,
                                            width: columnWidths[column.id]?.width,
                                            padding: "0px 16px"
                                        }}
                                    >
                                        {column.sortable ? (
                                            <TableSortLabel
                                                active={sortOrder.field === column.id}
                                                direction={sortOrder.direction}
                                                onClick={() => onSortChange(column.id)}
                                            >
                                            <Typography variant="T4">{column.label}</Typography>

                                            </TableSortLabel>
                                        ) : (
                                            <Typography variant="T4">{column.label}</Typography>                                          )}
                                    </TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>
                                {columns
                                    .filter((column) => visibleColumns[column.id])
                                    .map((column) => {
                                        const content = row[column.id];
                                        const isOverflowed =
                                            typeof content === "string" &&
                                            content.length * 8 > COLUMN_WIDTHS[column.id]?.width;

                                        const isSmallOverflow =
                                            isOverflowed && content.length < 80; // Slight overflow

                                        return (
                                            <TableCell
                                                key={column.id}
                                                style={{
                                                    minWidth: columnWidths[column.id]?.minWidth,
                                                    maxWidth: columnWidths[column.id]?.maxWidth,
                                                    width: columnWidths[column.id]?.width,
                                                    whiteSpace: isSmallOverflow ? "normal" : "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    lineHeight: isSmallOverflow ? "1.5" : "normal",
                                                    height: isSmallOverflow ? "auto" : "55px",
                                                    padding: "8px", // Slightly increase padding for better visibility
                                                }}
                                                title={isOverflowed && !isSmallOverflow ? content : ""}
                                            >
                                                {renderCellContent
                                                    ? renderCellContent(row, column, columnWidths[column.id]?.width)
                                                    : content}
                                            </TableCell>
                                        );
                                    })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )}
        </TableContainer>
    );
};

export default DataTable;
