import mixpanel from 'mixpanel-browser';
import {IdentifyUserUsageFunnel} from './usage_funnel_updater';

// Mixpanel Initialization
const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;
mixpanel.init(MIXPANEL_TOKEN, {
    debug:  process.env.REACT_APP_ENV === 'local', // Enable for development debugging
    persistence: 'localStorage', // Persist user state across sessions
});

// Function to identify the user
export const identifyUser = (user) => {
    if (user?.id) {
        mixpanel.alias(user.id); // Merge anonymous data with identified profile
        mixpanel.identify(user.id); // Set the user's unique identity
        mixpanel.people.set({
            $name: user.name,
            $email: user.email,
            user_usage_funnel : user.usage_funnel
        }); // Optionally set user properties
    } else {
        console.warn('User data is missing or invalid.');
    }
};

// Exported Function to Track Events
export const trackEvent = (eventName, properties = {}, user) => {
    mixpanel.track(eventName, properties);

    // Trigger funnel update observer
    if (user) {
        IdentifyUserUsageFunnel(user, eventName);
    }
};
