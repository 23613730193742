import logo from '../assets/images/LeanLogoDark.png';
import top_logo from '../assets/images/top_mobile.png';
import b_logo from '../assets/images/b_mobile.png';

const MobilePage = () => {
    return (
        <div
            style={{
                height: "100vh",
                display: "flex",
                backgroundColor: "#191924", // Black background
                color: "#FFFFFF", // White text
                fontFamily: "Inter, sans-serif",
            }}
        >
            {/* Top Image */}
            <img
                src={top_logo} // Replace with the actual path to your top image
                alt="Top Design"
                style={{
                    position: "absolute",
                    top: 0,
                    width: "100%", // Adjust size as needed
                }}
            />

            {/* Text Content */}
            <div style={{zIndex :1, marginLeft :"36px",
                display: "flex",
                flexDirection: "column",
                marginTop: "42px",
                marginRight : "100px"
            }}>
                <div style={{ opacity:"78%", fontSize: "18px"}}>
                    Connect with the right VC
                </div>
                <div style={{marginTop: "45%"}}>
                    <img
                        src={logo} // Add your logo path here
                        alt="Logo"
                        style={{width: "158px" ,marginBottom: "34px"}} // Adjust the size of your logo
                    />
                    <div style={{fontSize: "30px", lineHeight: "36px",fontWeight:"400", fontFamily:"Questrial, sans-serif"}}>
                        Our Experience Is Available on Every Desktop.
                    </div>
                </div>
                <div/>
            </div>

            {/* Bottom Image */}
            <img
                src={b_logo} // Replace with the actual path to your bottom image
                alt="Bottom Design"
                style={{
                    width: "100%", // Adjust size as needed
                    position: "absolute",
                    bottom: 0,
                }}
            />
        </div>)
};

export default  MobilePage
