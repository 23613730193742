import React, {useState, useEffect, memo} from 'react';
import {
    TextField,
    MenuItem,
    Select,
    Checkbox,
    Slider,
    FormControlLabel,
    CircularProgress, Typography, FormControl, Box
} from '@mui/material';
import { debounce } from 'lodash';
import api from '../../../services/api';
import mapImage from '../../../assets/images/map.png'


const MultiSelectFilter = memo(({ label, filterType, selectedOptions, appliedOptions, onChange , onlySearch, showSearch=true, customStyle={},variant ='default' }) => {
    const [options, setOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [tempSearch, setTempSearch] = useState(onlySearch);
    const [isLoading, setIsLoading] = useState(!onlySearch);

    const fetchOptions = async (query) => {
        if (!onlySearch || (onlySearch && query.trim().length > 0)) {
            try {
                const response = await api.get('/data/filter_suggestions', {
                    params: {filter_type: filterType, query},
                });
                setOptions(response.data);
            } catch (error) {
                console.error("Error fetching filter options:", error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const debouncedFetchOptions = debounce(fetchOptions, 300);

    useEffect(() => {
        debouncedFetchOptions(searchQuery);
        return () => debouncedFetchOptions.cancel();
    }, [searchQuery]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        if (onlySearch) {
            if (e.target.value.trim().length > 0) {
                setTempSearch(false)
            } else {
                setTempSearch(true)
            }
        }
    };

    const handleCheckboxChange = (option) => {
        const updatedOptions = selectedOptions.includes(option)
            ? selectedOptions.filter((item) => item !== option)
            : [...selectedOptions, option];
        onChange(updatedOptions);
    };

    // Sort options based on appliedOptions (only change after Apply is clicked)
    const sortedOptions = [...options].sort((a, b) => {
        const isSelectedA = appliedOptions.includes(a); // Use final applied state for sorting
        const isSelectedB = appliedOptions.includes(b);
        return isSelectedB - isSelectedA;
    });

    const defaultStyles = {
        flexDirection : "row",
        justifyContent: "normal",

        container: {
            backgroundColor: "#fff",
            borderRadius: "4px",
            border: "1px solid #ddd",
            padding: "6px 12px"
        },
        searchInput: {
            backgroundColor: "#F7F7F7",
            input: { color: "#000" },
            "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#ddd" },
                "&:hover fieldset": { borderColor: "#ccc" },
            },
            marginBottom: "8px",
        },
        optionList: {
            maxHeight: "200px",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
        },
        checkbox: {
            color: "#7F56D9",
            "&.Mui-checked": { color: "#7F56D9" },
        },
        optionLabel: { color: "#333" },
        chip: {
            backgroundColor: "#7F56D9",
            color: "#FFFFFF",
        },
    };

    const optionsToShow =  tempSearch && appliedOptions.length > 0 ? appliedOptions : sortedOptions;



    return (
        <div>
        <div>
            {(isLoading && !tempSearch) ? (
                <div style={{ display: 'flex', justifyContent: 'center', padding: '6px 12px' }}>
                    <CircularProgress size={24}
                    sx={{color : customStyle?.color}}/>
                </div>
            ) : (
                <div>
                    { showSearch && (
                        <TextField
                        label={`Search ${label}`}
                        variant="outlined"
                        size="small"
                        onChange={handleSearchChange}
                        value={searchQuery}
                        style={{ marginBottom: 8 }}
                    />)}

                    { (tempSearch && appliedOptions.length === 0) ? (
                        <div style={{ display: "flex", justifyContent: 'center', padding: '6px'}}>
                            <img src={mapImage} alt='mapImage' style={{ borderRadius: '72px' }} />
                            <div style={{alignItems : "center", display: 'flex'}}>
                            <Typography variant='body1' fontFamily='inter' sx={{pl:'14px'}}> Search to see results
                            </Typography>
                            </div>
                        </div>
                    ) :  (<div
                        style={{
                            maxHeight: '200px',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            ...(customStyle && customStyle.container),
                        }}
                    >
                            {optionsToShow.map((option) => (
                                <FormControlLabel
                                    key={option}
                                    label={
                                        <Typography
                                            sx={{...defaultStyles.optionLabel,
                                                ...(customStyle?.optionLabel),
                                                fontWeight:
                                                customStyle && customStyle.checked && selectedOptions.includes(option)
                                                ? customStyle.checked.fontWeight : undefined
                                            }}
                                        >
                                            {option}
                                        </Typography>
                                    }
                                    control={
                                        <Checkbox
                                            checked={selectedOptions.includes(option)}
                                            onChange={() => handleCheckboxChange(option)}
                                            icon={
                                                customStyle && customStyle?.checkIcon ? (
                                                    customStyle.checkIcon
                                                ) : undefined
                                            }
                                            checkedIcon={
                                                customStyle && customStyle?.checkIcon ? (
                                                    customStyle.checkIcon

                                                ) : undefined
                                            }
                                            sx={{
                                                ...(defaultStyles.checkbox),
                                                visibility:
                                                customStyle && customStyle?.checkbox  && !selectedOptions.includes(option)
                                                            ? customStyle.checkbox.notSelected.visibility
                                                            :"visible",
                                                "&.Mui-checked": {
                                                    color: "#7F56D9", // Custom blue color when checked
                                                },
                                                "&:hover": {
                                                    visibility: "visible", // Show checkbox on hover for custom style
                                                },
                                            }}
                                        />
                                    }
                                    sx={{
                                        display: "flex",
                                        paddingLeft :"6px",
                                        flexDirection: customStyle?  customStyle.flexDirection : defaultStyles.flexDirection, // Reverse for custom style
                                        justifyContent: customStyle?  customStyle.justifyContent : defaultStyles.justifyContent,
                                        backgroundColor:
                                            customStyle && customStyle?.checked
                                                ? selectedOptions.includes(option)
                                                    ? customStyle.checked.backgroundColor
                                                    : customStyle.container.backgroundColor
                                                : defaultStyles.container.backgroundColor,
                                        borderRadius: "8px",
                                        marginRight :0,
                                        "&:hover": customStyle && customStyle?.hover
                                            ? { backgroundColor: customStyle.hover.backgroundColor } // Custom hover background
                                            : {},
                                        "&:hover .MuiCheckbox-root": {
                                            visibility: "visible", // Show checkbox when hovering over row
                                        },                                    }}
                                />
                            ))}
                    </div>
                    )}
                </div>
            )}
        </div>
        </div>
    );
});

const SingleSelectFilter = memo(({ label, filterType, selectedOption, options, onChange, customStyle = {} }) => {
    const [dynamicOptions, setDynamicOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false); // Track if dropdown is open


    // Fetch options dynamically if not provided statically
    useEffect(() => {
        const fetchOptions = async () => {
            if (!options || options.length === 0) {
                setLoading(true);
                try {
                    const response = await api.get("/data/filter_suggestions", {
                        params: { filter_type: filterType },
                    });
                    setDynamicOptions(response.data);
                } catch (error) {
                    console.error("Error fetching options:", error);
                } finally {
                    setLoading(false);
                }
            } else {
                setDynamicOptions(options);
            }
        };

        fetchOptions();
    }, [filterType]);

    return (
        <div>
        <FormControl fullWidth>
            {loading ? (
                <CircularProgress size={24} />
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#262636",
                        borderRadius: "8px",
                        border: "1px solid #262636",
                        height: "48px",
                        cursor: "pointer",
                        position: "relative",
                    }}
                >
                    <Select
                        value={selectedOption}
                        onChange={(e) => onChange(e.target.value)}
                        onOpen={() => setOpen(true)}
                        onClose={() => setOpen(false)}
                        displayEmpty
                        fullWidth
                        renderValue={(selected) => {
                            if (!selected) {
                                return (
                                    <Typography
                                        variant="T1" sx={{color: "#C2C2C2",}}>
                                        {label}
                                    </Typography>
                                );
                            }
                            return (
                                <Typography
                                    variant="T1" sx={{color: "#C2C2C2",}}>
                                    {selected}
                                </Typography>
                            );
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    backgroundColor: customStyle.menuBackgroundColor || "#1A1A29", // Entire menu background color
                                    color: customStyle.menuTextColor || "#DADADA", // Text color for all menu items
                                    borderRadius: "8px", // Optional: rounded edges
                                },
                            },
                            MenuListProps: {
                                sx: {
                                    padding: "0 8px",
                                },
                            }
                        }}
                        sx={{
                            border: "none",
                            "& .MuiOutlinedInput-notchedOutline": { border: "none" },
                            "& .MuiSelect-icon": {
                                display: "none", // Hide default arrow
                            },
                        }}
                    >
                        {dynamicOptions.map((option) => (
                            <MenuItem key={option} value={option}
                                      sx={{
                                          padding: "6px 12px", // General padding for content
                                          fontWeight : customStyle.fontWeight || 500,
                                          backgroundColor: "transparent",
                                          color: customStyle.menuTextColor || "#FFFFFF",
                                          fontFamily: customStyle.fontFamily || "Arial",
                                          fontSize :customStyle.fontSize || '13px',
                                          "&:hover": {
                                              backgroundColor: customStyle.hoverBackgroundColor || "#2A2A36",
                                              color: customStyle.hoverTextColor || "#FFFFFF",
                                              borderRadius: "8px"
                                          },
                                          "&.Mui-selected": {
                                              backgroundColor: customStyle.activeBackgroundColor || "#3F3F52",
                                              color: customStyle.activeTextColor || "#FFFFFF",
                                              borderRadius: "8px"
                                          },
                                          "&.Mui-selected:hover": {
                                              backgroundColor: customStyle.activeHoverBackgroundColor || "#4A4A5E",
                                              color: customStyle.activeHoverTextColor || "#FFFFFF",
                                              borderRadius: "8px"
                                          },
                                      }}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>

                    {/* Custom Arrow */}
                    <Box
                        sx={{
                            width: "30px",
                            height: "30px",
                            background: "rgba(0, 0, 0, 0.24)",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            position: "absolute",
                            right: "12px",
                            pointerEvents: "none", // Makes arrow non-clickable
                        }}
                    >
                        <Box
                            sx={{
                                width: 0,
                                height: 0,
                                borderLeft: "6px solid transparent",
                                borderRight: "6px solid transparent",
                                borderTop: open
                                    ? "0" // Arrow up
                                    : "6px solid #1693EE", // Arrow down
                                borderBottom: open
                                    ? "6px solid #1693EE" // Arrow up
                                    : "0", // Arrow down
                                transition: "all 0.3s ease",
                            }}
                        />
                    </Box>
                </Box>
            )}
        </FormControl>
        </div>
    );
});

export default SingleSelectFilter;


const discreteRanges = [
    { label: 'Under 100K', value: 0.1 }, // 100K
    { label: '250K', value: 0.25 },
    { label: '500K', value: 0.5 },
    { label: '1M', value: 1 }, // 1M
    { label: '5M', value: 5 }, // 5M
    { label: '10M', value: 1 }, // 10M
    { label: '20M', value: 20 }, // 20M
    { label: '50M', value: 50 }, // 50M
    { label: '100M', value: 100 }, // 100M
    { label: '200M+', value: 200 }, // 200M+
];


const RangeFilter = ({ label, selectedRange, onChange }) => {
    const [range, setRange] = useState([0, discreteRanges.length - 1]); // Default to full range
    useEffect(() => {
        const startIndex = discreteRanges.findIndex(range => range.value >= selectedRange[0]);
        const endIndex = discreteRanges.findIndex(range => range.value >= selectedRange[1]);
        setRange([startIndex !== -1 ? startIndex : 0, endIndex !== -1 ? endIndex : discreteRanges.length - 1]);
    }, [selectedRange]);

    const handleRangeChange = (event, newRange) => {
        setRange(newRange);
        const transformedRange = [discreteRanges[newRange[0]].value, discreteRanges[newRange[1]].value];
        onChange(transformedRange);
    };

    return (
        <div style={{width: 200, margin: '0 8px'}}>
            <Typography variant="subtitle1">{label}</Typography>
            <Slider
                value={range}
                onChange={handleRangeChange}
                valueLabelDisplay="auto"
                marks={discreteRanges.map((range, index) => ({ value: index }))}
                step={null} // Disable intermediate steps
                min={0}
                max={discreteRanges.length - 1}
                valueLabelFormat={(index) => discreteRanges[index]?.label}
                getAriaValueText={(index) => discreteRanges[index]?.label}
                style={{color: '#7F56D9'}}
            />
            <div style={{display: 'flex', justifyContent: 'space-between', fontSize: '12px', color: '#888'}}>
                <span>{discreteRanges[0].label}</span>
                <span>{discreteRanges[discreteRanges.length - 1].label}</span>
            </div>
        </div>
    );
};


export {MultiSelectFilter, SingleSelectFilter, RangeFilter};
