import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import ChangePassword from './components/Users/ChangePassowrd';
import AppLayout from './components/AppWrapper/AppLayout';
import VCList from "./pages/VC/VCList";
import AuthPage from "./pages/Users/AuthPage";
import {ThemeProvider} from "@mui/material";
import customTheme from "./context/customTheme";
import VCPage from './pages/VC/VCPage';
import UserSettings from "./pages/Users/UserSettings";
import * as PropTypes from "prop-types";
import MobilePage from './pages/MobilePage'
import ProtectedRoute from "./components/common/ProtectedRoutes";
import FormFlow from "./pages/Company/ComapnyForm";
import "@fontsource/questrial";
import "@fontsource/fraunces";
import "@fontsource/inter";


function Redirect(props) {
    return null;
}

Redirect.propTypes = {to: PropTypes.string};
const App = () => {
    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Adjust the width for mobile detection
    };

    useEffect(() => {
        handleResize(); // Check on initial load
        window.addEventListener("resize", handleResize); // Add event listener for resize
        return () => window.removeEventListener("resize", handleResize); // Cleanup event listener
    }, []);

  return (isMobile ? (
      <MobilePage />
  ) : (
      <Router>
          <ThemeProvider theme={customTheme}>
        <AuthProvider>
            <Routes>
            {/* Public Routes */}
            <Route path="/login" element={<AuthPage isLogin={true} />} />
            <Route path="/signup" element={<AuthPage isLogin={false} />} />
            <Route path="/onboarding" element={
                <ProtectedRoute>
                    <FormFlow />
                </ProtectedRoute>
                    } />
            <Route element={<AppLayout />}>
                <Route path={"/profile"} element={<UserSettings />} />
                <Route path={"/change-password"} element={<ChangePassword />} />
                <Route path={"/"} element={<VCList key="vcs" isShortlist={false} />} />
                <Route path={"/shortlist"} element={<VCList key="shortlist" isShortlist={true} />} />
                <Route path="/vc/:vcId" element={<VCPage />} />
            </Route>
          </Routes>
        </AuthProvider>
          </ThemeProvider>
      </Router>
  ));
};

export default App;
