import React from 'react';
import { Menu, MenuItem, Checkbox, Typography } from '@mui/material';



const ColumnsDropdownMenu = ({ anchorEl, onClose, columns, visibleColumns, onToggleColumn }) => (
    <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
    >
        <Typography style={{ padding: "8px 16px", fontWeight: 600, color: "#333" }}>
            Choose Columns to View
        </Typography>
        {columns
            .filter(column => column.id !== "name" && column.id !== "in_shortlist")
            .map(column => (
                <MenuItem key={column.id} onClick={() => onToggleColumn(column.id)} style={{ paddingLeft: 16 }}>
                    <Checkbox
                        checked={visibleColumns[column.id]}
                        style={{ color: "#6B21A8", paddingRight: "8px" }}
                    />
                    <Typography style={{ color: "#333" }}>{column.label}</Typography>
                </MenuItem>
            ))}
    </Menu>
);

export default ColumnsDropdownMenu;
