import { createTheme } from '@mui/material/styles';
import { purple, grey } from '@mui/material/colors';

// Define custom theme with shared properties and overrides
const customTheme = createTheme({
    palette: {
        primary: {
            main: '#6B21A8', // Main purple color
            light: '#D6BBFB', // Lighter purple for hover/focused states
            dark: '#7F56D9',  // Dark purple for active states
            contrastText: '#FFFFFF', // Text color on primary backgrounds
        },
        secondary: {
            main: grey[600],  // Default gray for secondary elements
            light: grey[300], // Light gray for background elements
            dark: grey[800],  // Dark gray for text on secondary backgrounds
            contrastText: '#333', // Text color on secondary backgrounds
        },

        background: {
            default: '#FFFFFF',  // Default background color
            paper: '#FFFFFF',    // Light background for focused elements
        },
    },
    shape: {
        borderRadius: 8,  // Consistent border radius
    },
    typography: {
        fontFamily: 'Inter',
        button: {
            textTransform: 'none', // Keep buttons lowercase by default
        },
        T0: {
            fontSize: '18px',
            lineHeight: '28px',
            fontWeight: 600, // Semibold
        },
        T1: {
            fontSize: '16px',
            lineHeight: '24px',
            fontWeight: 500, // Semibold
        },
        T2: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 500, // Semibold
        },
        T3: {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: 400, // Regular
        },
        T4: {
            fontSize: '12px',
            lineHeight: '18px',
            fontWeight: 500, // Medium
        },
        T5: {
            fontSize: '30px',
            fontFamily :'Questrial',
            lineHeight: '40px',
            fontWeight: 400, // Regular
        },
        T6: {
            fontSize: '30px',
            fontFamily :'Questrial',
            lineHeight: '42px',
            fontWeight: 400, // Regular
        },
        H0: {
            fontSize: '60px',
            fontFamily :'Fraunces',
            lineHeight: '74px',
            fontWeight: 600,
        },


    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '320px',
                    backgroundColor: '#FFFFFF',
                    transition: 'background-color 0.3s, border-color 0.3s',
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            borderColor: grey[400],
                        },
                        '&:hover fieldset': {
                            borderColor: purple[200],
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: purple[200],
                        },
                    },
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    maxHeight: 300,
                    width: 300,
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    padding: "8px 0",
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    display: "flex",
                    alignItems: "center",
                    padding: "6px 12px",
                    fontSize: "14px",
                    fontWeight: 500,
                    color: grey[800],
                    backgroundColor: '#FFFFFF',
                    border: `1px solid ${grey[400]}`,
                    height: "40px",
                    cursor: "pointer",
                    transition: "background-color 0.3s, border 0.3s",
                    '&:hover': {
                        backgroundColor: purple[50],
                        borderColor: purple[200],
                        color: purple[900],
                    },
                    '&.Mui-focused': {
                        color: purple[900],
                        backgroundColor: purple[50],
                        border: `1px solid ${purple[200]}`,
                    },
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    border: '1px solid var(--Colors-Border-border-secondary, #EAECF0)', // Add border around the table
                    borderRadius: 8, // Optional: Add rounded corners if desired
                },
            },
        },
        MuiTableRow : {
            styleOverrides : {
                root: {
                    '&:nth-of-type(odd)': {
                        backgroundColor: "white",
                    },
                    '&:nth-of-type(even)': {
                        backgroundColor: "#F9FAFB",
                    },
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    transition: "background-color 0.3s, color 0.3s, border 0.3s",
                    '&.Mui-active': {
                        backgroundColor: purple[700],
                        color: '#FFFFFF',
                        border: `1px solid ${purple[900]}`,
                    },
                    '&.Mui-menuOpen': {
                        backgroundColor: '#FFFFFF',
                        color: grey[800],
                        border: `1px solid ${grey[400]}`,
                    },
                    '&:hover': {
                        backgroundColor: grey[50],
                        color: grey[800],
                        borderColor: grey[400],
                    },
                },
            },
        },
    },
});


export default customTheme;
