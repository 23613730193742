import React, {useContext, useEffect, useState} from 'react';
import { Typography, Card, CardContent, Tooltip, Box, IconButton } from '@mui/material';
import SwipeableViews from 'react-swipeable-views-react-18'; // For smooth pagination
import defaultVCLogo from '../../assets/images/VC_default _logo.jpg';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import api from "../../services/api";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom"; // Page indicator

const Recommendations = () => {
    const navigate = useNavigate(); // Initialize navigate hook
    const [recommendations, setRecommendations] = useState([]);
    const [activePage, setActivePage] = useState(0);
    const { user} = useContext(AuthContext);
    const name = user.name.split(' ')[0];

    const ITEMS_PER_PAGE = 6; // 6 VCs per page
    const TOTAL_PAGES = Math.ceil(recommendations.length / ITEMS_PER_PAGE);

    useEffect(() => {
        const fetchRecommendations = async () => {
            try {
                const response = await api.get('/company/recommendations');
                setRecommendations(response.data.slice(0, 24)); // Limit to 24 items
            } catch (error) {
                console.error('Error fetching recommendations:', error);
            }
        };
        fetchRecommendations();
    }, []);


    const handleChangeIndex = (index) => {
        setActivePage(index);
    };
    const handleCardClick = (vcId) => {
        navigate(`/vc/${vcId}`); // Navigate to VC detail page
    };

    if (!recommendations.length) return null; // Hide section if no recommendations

    return (
        <Box sx={{ marginBottom: '132px',
            backgroundImage: "url('blur_rec.png')",
            backgroundPosition:"center",
            backgroundSize: "contain", // Adjusts the image size
            backgroundRepeat: "no-repeat", // Prevent image repetition
            height: "510px",
        }}>
            <Box sx={{maxWidth:"610px"}}>
                <Typography variant="H0" component="div" sx={{ marginBottom: '12px' }}>
                    Welcome {name}
                </Typography>
                <Typography variant="T0" component="div" sx={{ marginBottom: '12px' }}>
                    Top VC Recommendations for You
                </Typography>
                <Typography variant="T1" component="div"  sx={{ marginBottom: '24px', color: '#323248',fontWeight: 400 }}>
                    According to your data, we recommended you start exploring these VC, based on your Industry, capital need and relevancy (out of 8K+)
                </Typography>
            </Box>

            {/* Swipeable Carousel */}
            <SwipeableViews
                index={activePage}
                onChangeIndex={handleChangeIndex}
                enableMouseEvents
            >
                {Array.from({ length: TOTAL_PAGES }).map((_, pageIndex) => (
                    <Box
                        key={pageIndex}
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(3, 280px)', // Exactly 3 columns with fixed card width
                            gap: '36px 36px',
                            justifyContent: 'center', // Centers the grid horizontally
                            alignContent: 'center',
                            margin: '0 auto', // Ensures the grid stays centered within its container
                            padding: '8px',
                        }}
                    >
                        {recommendations
                            .slice(pageIndex * ITEMS_PER_PAGE, (pageIndex + 1) * ITEMS_PER_PAGE)
                            .map((rec, index) => (
                                <Card
                                    key={index}
                                    onClick={() => handleCardClick(rec.vc_id)}
                                    style={{
                                        background: 'rgba(249, 250, 251, 0.9)',
                                        border: '1px solid rgba(0, 0, 0, 0.12)',
                                        boxShadow: '0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03)',
                                        borderRadius: "12px",
                                        width : "280px",
                                        height :"160px",
                                        cursor :"pointer"
                                    }}
                                >
                                    <CardContent>
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginBottom: '8px',
                                            }}
                                        >
                                            <img
                                                src={rec.logo || defaultVCLogo}
                                                alt={`${rec.name} Logo`}
                                                style={{
                                                    width: '40px',
                                                    height: '40px',
                                                    marginRight: '12px',
                                                    borderRadius: '50%',
                                                }}
                                                onError={(e) => (e.target.src = defaultVCLogo)}
                                            />
                                            <Typography variant="T1" component="div"
                                                        sx={{color :"#0C0C12", fontSize :"18px"}}>
                                                {rec.name}
                                            </Typography>
                                        </div>
                                        <Tooltip title={rec.info}>
                                            <Typography
                                                variant="T2"
                                                style={{
                                                    color: '#3F3F6F',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: '-webkit-box',
                                                    WebkitLineClamp: 3,
                                                    WebkitBoxOrient: 'vertical',
                                                    whiteSpace: 'normal',
                                                    lineHeight : "21px"
                                                }}
                                            >
                                                {rec.info}
                                            </Typography>
                                        </Tooltip>
                                    </CardContent>
                                </Card>
                            ))}
                    </Box>
                ))}
            </SwipeableViews>

            {/* Page Indicators */}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '12px',
                }}
            >
                {Array.from({ length: TOTAL_PAGES }).map((_, index) => (
                    <IconButton
                        key={index}
                        onClick={() => setActivePage(index)}
                        sx={{
                            color: index === activePage ? '#B2DDFF' : '#C7CBCE',
                            transition: 'color 0.3s',
                            height: "9px",
                            width: "9px",
                            margin :'0 2px'
                        }}
                    >
                        <FiberManualRecordIcon fontSize="small" />
                    </IconButton>
                ))}
            </Box>
        </Box>
    );
};

export default Recommendations;
