import React from 'react';
import { Outlet } from 'react-router-dom';
import AppWrapper from './AppWrapper';
import ProtectedRoute from "../common/ProtectedRoutes"; // Your AppWrapper component

const AppLayout = () => {
    return (
        <AppWrapper>
            <ProtectedRoute>
                <Outlet />
            </ProtectedRoute>
        </AppWrapper>
    );
};

export default AppLayout;
