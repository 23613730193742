import React from 'react';
import NavBar from './NavBar';
import Sidebar from './Sidebar'; // Sidebar component
import './AppWrapper.css';
import sidebarButtons from "../../utils/Consts";

const AppWrapper = ({ children }) => {
    return (
        <div className="app-wrapper">
            <NavBar />
            <div className="main-content">
                <Sidebar buttons={sidebarButtons}/>
                <div className="page-content"
                     style={{
                         padding: '48px',
                     }}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default AppWrapper;
