import React from 'react';
import GoogleLoginButton from '../../components/Users/GoogleLoginButton';
import LinkedInLoginButton from '../../components/Users/LinkedinLogin';
import './auth.css';
import {Link} from "react-router-dom";
import beta_logo from "../../assets/images/LeanLogoDark.png"; // Common styles for both login and signup
import login_decorative from "../../assets/images/login_decorative.png"; // Common styles for both login and signup\

const AuthPage = ({isLogin}) => {


    return (
        <div>
            <div className="auth-container">
                <div className="auth-section">
                    <div className="auth-content">
                        <div className="logo-container">
                            <div className="logo-circle">
                                <img src={beta_logo} alt="VentureLink Logo" style={{paddingLeft: "12px", width: "184px"}}/>
                            </div>
                        </div>
                        <div className="main-container">
                            <div>
                                <h1 className="welcome-text">{isLogin ? 'Welcome Back' : 'Welcome!'}</h1>
                                <p className="subtext">
                                    {isLogin
                                        ? '8,800 VCs are waiting'
                                        : 'VentureLink is completely free while in Beta.\nPlease choose one of the options below'}
                                </p>
                            </div>
                            <LinkedInLoginButton/>
                            <GoogleLoginButton/>
                            <p className="subtext">
                                {isLogin
                                    ? ''
                                    : '8,800 VCs are waiting'}
                            </p>
                        </div>
                        <div className="footer">

                            <p className="auth-toggle-text">
                                {isLogin ? "Don't have an account?" : 'Already have an account?'}{' '}
                                <Link to={isLogin ? '/signup' : '/login'}>
                                    {isLogin ? 'Sign up' : 'Log in'}
                                </Link>

                            </p>
                        </div>
                        </div>
                    </div>
                    <div className="image-section">
                    <img src={login_decorative} alt="VentureLink Logo"/>
                </div>
            </div>
        </div>
    );
};

export default AuthPage;
