import React, {useState, useEffect, useRef} from "react";
import { Box, Button, TextField, Typography, CircularProgress, Popover } from "@mui/material";
import { MultiSelectFilter, SingleSelectFilter } from "../common/Filter/FilterComponents";
import {multiSelectStyle, singleCustomStyle} from "./style";
import {blue} from "@mui/material/colors";

const DynamicFormPage = ({ config, step, formData, setFormData, onNext, onBack, isLastStep, errors, setErrors }) => {
    const { fields } = config;
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentField, setCurrentField] = useState(null);
    const [tempSelections, setTempSelections] = useState([]);
    const parentRef = useRef(null); // Ref for button size
    const [menuWidth, setMenuWidth] = useState(0);


    const validateField = (fieldName, value) => {
        const newErrors = { ...errors };
        if (fieldName === "amount") {
            const amountRegex = /^[0-9]+(K|M|B)$/; // Match numbers with K, M, or B
            if (value && !amountRegex.test(value)) {
                newErrors[fieldName] = "Amount must be a number followed by K, M, or B (e.g., 500K, 1M, 2B).";
            } else {
                delete newErrors[fieldName];
            }
        }
        if (JSON.stringify(errors) !== JSON.stringify(newErrors)) {
            setErrors(newErrors);
        }
    };


    useEffect(() => {
        // Adjust menu width when parent is rendered
        if (parentRef.current) {
            setMenuWidth(parentRef.current.offsetWidth);
        }
    }, [parentRef]);

    // Handle opening dropdown/popover
    const handleClick = (event, field) => {
        setAnchorEl(event.currentTarget);
        setCurrentField(field);
        setTempSelections(formData[field.name] || []);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setCurrentField(null);
    };

    const handleApply = () => {
        setFormData((prev) => ({ ...prev, [currentField.name]: tempSelections }));
        handleClose();
    };

    const handleClearSelection = () => {
        setFormData((prev) => ({ ...prev, [currentField.name]: [] }));
        handleClose();
    };

    const handleTempSelectionChange = (updatedSelections) => {
        setTempSelections(updatedSelections);
    };

    return (
        <Box
            sx={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#191924",
                color: "#fff",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 5, // Space between fields
                    padding: 4,
                    height: "80%",
                    width: "100%",
                    maxWidth : 400
                }}
            >
                {fields.map((field, index) => (
                    <Box key={index} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        {/* Subtitle */}
                        <Typography variant="T1" sx={{fontSize :"15px", maxWidth : 320}}>
                            {field.subtitle || field.label}
                        </Typography>

                        {/* Field Rendering */}
                        {field.type === "multi-select" && (
                            <Box
                                ref={parentRef}
                                onClick={(e) => handleClick(e, field)} // Trigger popover directly
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    backgroundColor: "#262636",
                                    borderRadius: "8px",
                                    height: "48px",
                                    padding: "0 12px",
                                    border: "1px solid transparent",
                                    cursor: "pointer",
                                    position: "relative",
                                }}
                            >
                                {/* Text: Label and Selected Count */}
                                <Typography
                                    variant="T1" sx={{color: "#C2C2C2"}}>
                                    {field.label} ({(formData[field.name] || []).length || 0} selected)
                                </Typography>

                                {/* Arrow Container */}
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "30px",
                                        height: "48px",
                                        background: "#262636",
                                        borderRadius: "62px",
                                        position: "relative",
                                    }}
                                >
                                    {/* Inner Circle */}
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "30px",
                                            height: "30px",
                                            background: "rgba(0, 0, 0, 0.24)",
                                            borderRadius: "50%",
                                        }}
                                    >
                                        {/* Down Arrow Icon */}
                                        <Box
                                            sx={{
                                                width: "0",
                                                height: "0",
                                                borderLeft: "6px solid transparent",
                                                borderRight: "6px solid transparent",
                                                borderTop: Boolean(anchorEl)
                                                    ? "0" // Remove the top border
                                                    : "6px solid #1693EE", // Down Arrow
                                                borderBottom: Boolean(anchorEl)
                                                    ? "6px solid #1693EE" // Up Arrow
                                                    : "0", // Remove the bottom border
                                                transition: "all 0.3s ease", // Smooth transition
                                            }}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        )}

                        {field.type === "single-select" && (
                            <SingleSelectFilter
                                label={field.label}
                                filterType={field.name}
                                selectedOption={formData[field.name] || ""}
                                options={field.options || []}
                                onChange={(value) =>
                                    setFormData((prev) => ({ ...prev, [field.name]: value }))
                                }
                                customStyle={singleCustomStyle}
                            />
                        )}

                        {field.type === "text" && (
                            <TextField
                                placeholder={field.placeholder}
                                value={formData[field.name] || ""}
                                multiline={field.multiline || false} // Enable multiline if specified in config
                                rows={field.multiline ? 6: 1} // 4 rows for multiline, default to 1 otherwise
                                onChange={(e) =>
                                    setFormData((prev) => ({
                                        ...prev,
                                        [field.name]: e.target.value,
                                    }))
                                }
                                onBlur={(e) => {
                                    validateField(field.name, e.target.value); // Validate on blur
                                }}
                                sx={{
                                    // Local styles to override global theme
                                    backgroundColor: "#191924",
                                    width: "auto",
                                    borderRadius: "8px",
                                    border: "1px solid #FFFFFF7A",
                                    "& .MuiOutlinedInput-root": {
                                        "& fieldset": {
                                            borderColor : "#FFFFFF7A"
                                        },
                                        "&:hover fieldset": {
                                            borderColor : "#FFFFFF7A"
                                        },
                                        "&.Mui-focused fieldset": {
                                            borderColor : "#FFFFFF7A"
                                        },
                                    },
                                }}
                                InputProps={{
                                    sx: { color: "#fff",
                                        fontWeight : 500,
                                        fontSize :"15px",
                                    },
                                }}
                                error={Boolean(errors[field.name])}
                                helperText={errors[field.name] || ""}
                            />
                        )}
                    </Box>
                ))}

                {/* Popover for Multi-Select */}
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "left" }}
                    PaperProps={{
                        style: {
                            width: `${menuWidth}px`,
                            borderRadius: "8px",
                        },
                    }}

                >
                    {currentField?.type === "multi-select" && (
                        <Box sx={{ p: 2, minWidth: "250px", backgroundColor : "#0C0C12", color :"white" }}>
                            <MultiSelectFilter
                                label={currentField.label}
                                filterType={currentField.name}
                                selectedOptions={tempSelections}
                                appliedOptions={formData[currentField.name] || []}
                                onChange={handleTempSelectionChange}
                                onlySearch={false}
                                showSearch={false}
                                customStyle={multiSelectStyle}
                            />


                            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                                <Button variant="text" onClick={handleClearSelection}
                                        sx={{ color: "#1693EE",
                                            backgroundColor :"white",
                                            borderColor : "white",
                                            '&:hover': {
                                                backgroundColor: "#1693EE",
                                                borderColor: blue[200],
                                                color: blue[50],
                                            }
                                        }}>
                                    Clear selection
                                </Button>
                                <Button variant="contained" onClick={handleApply}
                                        sx={{ ml: 2, color: "white",
                                        backgroundColor :"#1693EE",
                                        borderColor : "#1693EE",
                                        '&:hover': {
                                            backgroundColor: blue[50],
                                            borderColor: blue[200],
                                            color: blue[900],
                                        }
                                }}>
                                    Apply
                                </Button>
                            </Box>
                        </Box>
                    )}
                </Popover>


                {/* Continue/Back Buttons */}
                <Box>
                    <Box sx={{ height :"1px", backgroundColor :"#FFFFFF7A", width : "100%"}}></Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
                    <Box>
                    {step > 1 && (
                        <Button variant="outlined" onClick={onBack}
                                sx={{ color: "#1693EE",
                                    mb:2,
                                    backgroundColor :"white",
                                    borderColor : "white",
                                    borderRadius : "8px",
                                    '&:hover': {
                                        backgroundColor: "#1693EE",
                                        borderColor: blue[200],
                                        color: blue[50],
                                    }
                                }}>
                            Back
                        </Button>
                    )}
                    <Typography variant="T1" sx={{color: "#FFFFFF7A", fontSize: "15px", alignItems :"center", display :"flex"}}>{step}/2</Typography>
                    </Box>
                        <Button variant="contained" onClick={onNext}
                                sx={{ ml: 2, color: "white",
                                    backgroundColor :"#1693EE",
                                    borderColor : "#1693EE",
                                    borderRadius : "8px",
                                    '&:hover': {
                                        backgroundColor: blue[50],
                                        borderColor: blue[200],
                                        color: "#1693EE",
                                    }
                                }}>
                                {isLastStep ? "See Recommended VCs ->" : "Continue"}
                        </Button>
                </Box>
            </Box>
            </Box>
        </Box>
    );
};

export default DynamicFormPage;
