
export const multiSelectStyle = {
    flexDirection : "row-reverse",
    color: "#1683D5", // Checkmark color
    fitWidth: true,
    justifyContent: "space-between",
    container: {
            padding: "12px",
    },
    searchInput: {
        backgroundColor: "#F7F7F7",
            input: { color: "#000" },
        "& .MuiOutlinedInput-root": {
            "& fieldset": { borderColor: "#ddd" },
            "&:hover fieldset": { borderColor: "#ccc" },
        },
        marginBottom: "8px",
    },
    checkIcon: <span
        style={{
            width: "24px",
            height: "24px",
            borderRadius: "50%",
            backgroundColor: "#191924", // Custom background for checked
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#1683D5", // Checkmark color
            fontSize: "16px",
        }}
    >
        ✔
    </span>,

    checkbox: {
        notSelected: {
            visibility: "hidden",
        },
    },
    optionLabel:
        {
            color: "white",
            fontSize: "15px", fontWeight: "500",
        },
    hover: {
        backgroundColor: "#191924"
    },
    checked: {
        backgroundColor: "#262636",
        fontWeight: "600"
    }

}


export const singleCustomStyle = {
    menuBackgroundColor: "#0C0C12",
    menuTextColor: "#FFFFFF",
    hoverBackgroundColor: "#191924",
    hoverTextColor: "#FFFFFF",
    activeBackgroundColor: "#262636",
    activeTextColor: "#FFFFFF",
    activeHoverBackgroundColor: "#262636",
    activeHoverTextColor: "#FFFFFF",
    fontFamily: "Inter, sans-serif",
    fontWeight : 500,
    fontSize: "15px",
};
